@import url("https://fonts.googleapis.com/css2?family=Exo+2&family=Nunito+Sans:wght@400;600;700&display=swap");

:root {
  --clr-primary: #2459ea;
  --clr-secondary-blue-50: #e9eefd;
  --clr-secondary-blue-100: #d3defb;
  --clr-secondary-blue-200: #a7bcf6;
  --clr-secondary-blue-300: #7b9bf2;
  --clr-secondary-blue-400: #507aee;
  --clr-secondary-blue-500: #2459ea;
  --clr-secondary-blue-600: #2050d3;
  --clr-secondary-blue-700: #193ea4;
  --clr-secondary-blue-800: #122d75;
  --clr-secondary-blue-900: #0b1b46;

  --clr-leaftech-blue-50: #f4f8ff;
  --clr-leaftech-blue-100: #e4ebff;
  --clr-leaftech-blue-200: #c5d7ff;
  --clr-leaftech-blue-300: #a1c0ff;
  --clr-leaftech-blue-400: #72a6ff;
  --clr-leaftech-blue-500: #0087ff;
  --clr-leaftech-blue-600: #0078e4;
  --clr-leaftech-blue-700: #0068c5;
  --clr-leaftech-blue-800: #0055a1;
  --clr-leaftech-blue-900: #003c72;

  --clr-mystic-red: #a13400;
  --clr-mystic-red-200: #ffccc5;
  --clr-mystic-red-300: #ffada1;
  --clr-mystic-red-400: #ff8872;
  --clr-mystic-red-500: #ff5300;
  --clr-mystic-red-600: #e44a00;
  --clr-mystic-red-700: #c54000;
  --clr-mystic-red-800: #a13400;

  --clr-cathode-green-200: #c5ffcf;
  --clr-cathode-green-400: #72ff90;
  --clr-cathode-green-600: #00e459;
  --clr-cathode-green-900: #00722c;
  --clr-cathode-green-800: #00a13f;
  --clr-cathode-green-700: #00c54d;

  --clr-star-200: #fff4c5;
  --clr-star-400: #ffea72;
  --clr-star-600: #e4cc00;
  --clr-star-700: #c5b100;
  --clr-star-800: #a19000;

  --clr-tangerine-200: #ffdac5;
  --clr-tangerine-400: #ffad72;
  --clr-tangerine-500: #ff9200;
  --clr-tangerine-600: #e48200;
  --clr-tangerine-800: #a15c00;

  --clr-white: white;
  --clr-black: black;

  --clr-gray-50: #f9f9f9;
  --clr-gray-100: #eff0f3;
  --clr-gray-200: #d2d3d9;
  --clr-gray-300: #bcbdc7;
  --clr-gray-400: #a6a7b4;
  --clr-gray-500: #9092a2;
  --clr-gray-600: #737481;
  --clr-gray-700: #565761;
  --clr-gray-800: #393a40;
  --clr-gray-900: #1c1d20;

  --clr-acanthus-900: #43444a;
  --clr-acanthus-500: #9899a7;
  --clr-acanthus-600: #878895;

  --clr-vivid-orchid-200: #eac5ff;
  --clr-vivid-orchid-400: #d472ff;
  --clr-vivid-orchid-600: #b200e4;
  --clr-vivid-orchid-800: #7e00a1;
  --clr-vivid-orchid-300: #dfa1ff;
  --clr-vivid-orchid-500: #c800ff;

  --clr-plunge-200: #c5ffeb;
  --clr-plunge-400: #72ffd5;
  --clr-plunge-600: #00e4b4;
  --clr-plunge-500: #00ffca;
  --clr-plunge-700: #00c59c;
  --clr-plunge-800: #00a17f;

  --clr-bright-indigo-200: #d2c5ff;
  --clr-bright-indigo-400: #9a72ff;
  --clr-bright-indigo-800: #4900a1;
  --clr-bright-indigo-700: #5a00c5;
  --clr-bright-indigo-600: #6800e4;
  --clr-bright-indigo-50: #f7f5ff;

  --box-shadow-elevation-100: 0px 0.2px 0.6px rgba(144, 146, 162, 0.041),
    0px 0.5px 1.8px rgba(144, 146, 162, 0.059),
    0px 1.2px 4.2px rgba(144, 146, 162, 0.077),
    0px 4px 14px rgba(144, 146, 162, 0.11);
  --box-shadow-elevation-200: 0px 0.4px 1.3px rgba(144, 146, 162, 0.041),
    0px 1px 3.5px rgba(144, 146, 162, 0.059),
    0px 2.4px 8.4px rgba(144, 146, 162, 0.077),
    0px 8px 28px rgba(144, 146, 162, 0.11);
  --box-shadow-elevation-400: 0px 1.4px 5.1px rgba(144, 146, 162, 0.041),
    0px 4px 14px rgba(144, 146, 162, 0.059),
    0px 9.6px 33.8px rgba(144, 146, 162, 0.077),
    0px 32px 112px rgba(144, 146, 162, 0.11);
  --box-shadow-up-hover: 0px 0.2px 0.6px rgba(36, 89, 234, 0.041),
    0px 0.5px 1.8px rgba(36, 89, 234, 0.059),
    0px 1.2px 4.2px rgba(36, 89, 234, 0.077),
    0px 4px 14px rgba(36, 89, 234, 0.11);
}

/* 
===================================
Reset styles
===================================
 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


*:focus {
  outline: none;
}


/* *::first-letter {
  text-transform: uppercase;
} */

/* TODO: remove this line after tailwind implementation is finished. */
html {
  font-size: 62.5%;
  color: var(--clr-gray-900);
}

body {
  background-color: var(--clr-gray-50);
}

a {
  text-decoration: none;
  cursor: pointer;
}

ul,
ol {
  list-style: none;
}

img {
  display: block;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: white;
}

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
  background-color: white;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border-width: 3px;
  background-color: var(--clr-gray-300);
}

.barchart-bg-gradient {
  background: linear-gradient(90deg,
      #00e459 0%,
      #e4cc00 35.2%,
      #e48200 65.78%,
      #e44a00 100%);
}

.barchart-bg-gradient-energy-performance {
  background: linear-gradient(90deg,
      #00e459 0%,
      #e4cc00 28.2%,
      #e48200 80.78%,
      #e44a00 84.3%,
      #e44a00 100%);
}

.barchart-new-bg-gradient-energy-performance {
  background: linear-gradient(to right, #1E955B, #51AB4A, #CADC09, #FFFD0A, #FDF400, #FAA112, #FE553D, #F94D48);
}


.App {
  background-color: var(--clr-gray-50);
  font-size: 2rem;
  color: #9747ff;
  text-align: center;
}

.style-title {
  text-transform: capitalize;
  margin-bottom: 2rem;
}

.style {
  margin: 4rem;
  padding: 3rem;
  /* border: 2px dashed #9747ff; */
  width: 95%;
  margin: auto;
}

.state {
  display: flex;
  width: 90%;
  height: 100px;
  margin: auto;
  margin-bottom: 2rem;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
}

.state-title {
  width: 15%;
}

.mybutton {
  width: 30%;
}

/* 
===================================
Global styles
===================================
 */
.capitalize {
  text-transform: capitalize;
}

.sentence-case {
  text-transform: lowercase;
}

.sentence-case::first-letter {
  text-transform: capitalize;
}

img {
  /* display: block;
  width: 100%; */
  object-fit: cover;
  object-fit: fill;
  /* object-fit: contain; */
  /* object-fit: scale-down; */
  /* object-fit: none; */
  /* object-position: center; */
}

.border-b-gray-100 {
  border-bottom: 1px solid var(--clr-gray-100);
}

.border-t-gray-100 {
  border-top: 1px solid var(--clr-gray-100);
}

.border-b-blue-50 {
  border-bottom: 1px solid var(--clr-secondary-blue-50);
}

.short-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* 
=================================
Font styles
=================================
*/
.t-heading-xxl {
  font-family: "Exo 2";
  font-size: 4rem;
  font-weight: 400;
  line-height: 5rem;
  letter-spacing: 0em;
}

.t-heading-xl {
  font-family: "Exo 2";
  font-size: 4rem;
  font-weight: 400;
  line-height: 5rem;
  letter-spacing: 0rem;
}

.t-heading-l2 {
  font-family: "Nunito Sans";
  font-size: 2.6rem;
  font-weight: 700;
  line-height: 3.54rem;
  letter-spacing: 0em;
}

.t-heading-l {
  font-family: "Nunito Sans";
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.6rem;
  letter-spacing: 0em;
}

/* there was no name for this typography */
.t-heading-l0 {
  font-family: "Nunito Sans";
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.6rem;
}

.t-heading-m {
  font-family: "Nunito Sans";
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.8rem;
  letter-spacing: 0rem;
}

.t-subheading-m {
  font-family: "Nunito Sans";
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.9rem;
  letter-spacing: 0.03rem;
}

.t-label-xs {
  font-family: "Nunito Sans";
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.2rem;
  letter-spacing: 0.1rem;
}

.t-label-m-caps {
  font-family: "Nunito Sans";
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4rem;
  letter-spacing: 0.1em;
}

.t-label-m2-caps {
  font-family: "Nunito Sans";
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.4rem;
  letter-spacing: 0.1em;
}

.t-label-m {
  font-family: "Nunito Sans";
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.4rem;
  letter-spacing: 0.025rem;
}

.t-label-l {
  font-family: "Exo 2";
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.3rem;
  letter-spacing: 0.02em;
}

.t-heading-s-700 {
  font-family: "Nunito Sans";
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.9rem;
  letter-spacing: 0.03rem;
}

.t-heading-s-600 {
  font-family: "Nunito Sans";
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.9rem;
  letter-spacing: 0.03rem;
}

/* mobile label button */
.t-heading-s {
  font-family: "Nunito Sans";
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.9rem;
  letter-spacing: 0.03rem;
}

.t-body-n {
  font-family: "Nunito Sans";
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.9rem;
  letter-spacing: 0rem;
}

.t-body-xl-700 {
  font-family: "Nunito Sans";
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: 0em;
}

.t-body-xl {
  font-family: "Nunito Sans";
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
  letter-spacing: 0em;
}

.t-body-xl-400 {
  font-family: "Nunito Sans";
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0em;
}

.t-body-xs {
  font-family: "Nunito Sans";
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0em;
}

.t-body-l {
  font-family: "Nunito Sans";
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.9rem;
  letter-spacing: 0rem;
}

.t-body-m {
  font-family: "Nunito Sans";
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.3rem;
  letter-spacing: 0rem;
}

.t-body-s {
  font-family: "Nunito Sans";
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.6rem;
  letter-spacing: 0rem;
}

.t-numbers-xxl {
  font-family: "Exo 2";
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 3.8rem;
  letter-spacing: 0em;
}

.t-numbers-xxs {
  font-family: "Exo 2";
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.4rem;
  letter-spacing: 0em;
}

.t-numbers-xl {
  font-family: "Exo 2";
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 2.4rem;
  letter-spacing: 0em;
}

.t-numbers-l {
  font-family: "Exo 2";
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.6rem;
  letter-spacing: 0em;
}

.t-numbers-m {
  font-family: "Exo 2";
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.8rem;
  letter-spacing: 0em;
}


.t-numbers-m-reg {
  font-family: "Exo 2";
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.8rem;
  letter-spacing: 0em;
}

.t-mobile-label {
  font-family: "Nunito Sans";
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.2rem;
  letter-spacing: 0.03em;
}

.section-center {
  padding-left: 3.2rem;
  padding-right: 3.2rem;
}

.articles-container {
  margin-top: 1rem;
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
}

.Toastify__toast {
  font-family: "Exo 2";
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.7rem;
  letter-spacing: 0.04em;
}


.slick-prev:before,
.slick-next:before {
  color: black;
}

.rs-picker-menu {
  z-index: 1381 !important;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

.leaflet-control-layers label {
  text-align: left;
  display: block;
  padding: 3px;
  cursor: pointer;
}

.leaflet-control-layers-base,
.leaflet-control-layers-overlays {
  font-size: 12px;
}


/* Spinner rotation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
    left: 0;
  }

  100% {
    transform: rotate(360deg);
    left: 100%;
  }
}

.icon-spin {
  animation: spin 1.5s linear infinite;
}


@keyframes opacity {
  0% {
    opacity: 0
  }

  50% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

.icon-opacity {
  animation: opacity 1s linear infinite;
}