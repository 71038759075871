.rs-btn {
  /* transition: all 0.3s ease-out; */
  font-family: "Nunito Sans";
  font-weight: 400;
  letter-spacing: 0.03rem;
  outline: none;
  border-radius: 7px;
  cursor: pointer;
}
.rs-btn-primary {
  background-color: var(--clr-secondary-blue-500);
}
.rs-btn-primary:hover:enabled {
  box-shadow: var(--box-shadow-up-hover);
  background: var(--clr-secondary-blue-700);
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: var(--clr-secondary-blue-500);
}
.rs-btn-link {
  color: var(--clr-secondary-blue-500);
  font-family: "Nunito Sans";
  font-weight: 400;
  letter-spacing: 0.03rem;
}
.rs-calendar-table-cell-content {
  font-family: "Nunito Sans";
  font-weight: 500;
  letter-spacing: 0.03rem;
}
.rs-calendar-table-cell-in-range:before {
  background-color: #e9eefd90;
}
.rs-calendar-header-title {
  font-family: "exo 2";
  font-size: 16px;
}
.rs-calendar-month-dropdown-year-active {
  color: var(--clr-secondary-blue-500);
}
.rs-calendar-month-dropdown-year {
  font-family: "Nunito Sans";
  font-weight: 400;
  letter-spacing: 0.03rem;
  font-size: 14px;
}
.rs-picker-toggle-value {
  font-size: 13px;
}
.rs-calendar-month-dropdown-cell-active
  .rs-calendar-month-dropdown-cell-content {
  background-color: var(--clr-secondary-blue-500);
}
