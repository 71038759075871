@import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLypxLcf-5JS7px-XEl3ieo8uMtisaQnAMaQTej6fzumLTpxJGDt9_iYoJB-VVF7ZDKH-yQehg7pxNcYqIz7hZpgHt5I);
.lst-kix_list_4-1 > li {
  counter-increment: lst-ctn-kix_list_4-1;
}
ol.lst-kix_list_3-1 {
  list-style-type: none;
}
ol.lst-kix_list_3-2 {
  list-style-type: none;
}
.lst-kix_list_3-1 > li {
  counter-increment: lst-ctn-kix_list_3-1;
}
ol.lst-kix_list_3-3 {
  list-style-type: none;
}
ol.lst-kix_list_3-4.start {
  counter-reset: lst-ctn-kix_list_3-4 0;
}
ol.lst-kix_list_3-4 {
  list-style-type: none;
}
ul.lst-kix_list_1-0 {
  list-style-type: none;
}
ol.lst-kix_list_3-0 {
  list-style-type: none;
}
.lst-kix_list_3-0 > li:before {
  content: "" counter(lst-ctn-kix_list_3-0, decimal) ". ";
}
ol.lst-kix_list_3-1.start {
  counter-reset: lst-ctn-kix_list_3-1 0;
}
.lst-kix_list_3-1 > li:before {
  content: "" counter(lst-ctn-kix_list_3-1, lower-latin) ". ";
}
.lst-kix_list_3-2 > li:before {
  content: "" counter(lst-ctn-kix_list_3-2, lower-roman) ". ";
}
.lst-kix_list_4-0 > li {
  counter-increment: lst-ctn-kix_list_4-0;
}
ul.lst-kix_list_1-3 {
  list-style-type: none;
}
.lst-kix_list_3-5 > li:before {
  content: "" counter(lst-ctn-kix_list_3-5, lower-roman) ". ";
}
ul.lst-kix_list_1-4 {
  list-style-type: none;
}
ul.lst-kix_list_1-1 {
  list-style-type: none;
}
.lst-kix_list_3-4 > li:before {
  content: "" counter(lst-ctn-kix_list_3-4, lower-latin) ". ";
}
ul.lst-kix_list_1-2 {
  list-style-type: none;
}
ul.lst-kix_list_1-7 {
  list-style-type: none;
}
.lst-kix_list_3-3 > li:before {
  content: "" counter(lst-ctn-kix_list_3-3, decimal) ". ";
}
ol.lst-kix_list_3-5 {
  list-style-type: none;
}
ul.lst-kix_list_1-8 {
  list-style-type: none;
}
ol.lst-kix_list_3-6 {
  list-style-type: none;
}
ul.lst-kix_list_1-5 {
  list-style-type: none;
}
ol.lst-kix_list_3-7 {
  list-style-type: none;
}
ul.lst-kix_list_1-6 {
  list-style-type: none;
}
ol.lst-kix_list_3-8 {
  list-style-type: none;
}
.lst-kix_list_3-8 > li:before {
  content: "" counter(lst-ctn-kix_list_3-8, lower-roman) ". ";
}
.lst-kix_list_3-6 > li:before {
  content: "" counter(lst-ctn-kix_list_3-6, decimal) ". ";
}
.lst-kix_list_4-3 > li {
  counter-increment: lst-ctn-kix_list_4-3;
}
.lst-kix_list_3-7 > li:before {
  content: "" counter(lst-ctn-kix_list_3-7, lower-latin) ". ";
}
ol.lst-kix_list_4-5.start {
  counter-reset: lst-ctn-kix_list_4-5 0;
}
ol.lst-kix_list_3-7.start {
  counter-reset: lst-ctn-kix_list_3-7 0;
}
ol.lst-kix_list_4-2.start {
  counter-reset: lst-ctn-kix_list_4-2 0;
}
.lst-kix_list_3-2 > li {
  counter-increment: lst-ctn-kix_list_3-2;
}
.lst-kix_list_4-4 > li {
  counter-increment: lst-ctn-kix_list_4-4;
}
.lst-kix_list_4-8 > li:before {
  content: "" counter(lst-ctn-kix_list_4-8, lower-roman) ". ";
}
.lst-kix_list_4-7 > li:before {
  content: "" counter(lst-ctn-kix_list_4-7, lower-latin) ". ";
}
ol.lst-kix_list_4-1.start {
  counter-reset: lst-ctn-kix_list_4-1 0;
}
ol.lst-kix_list_4-8.start {
  counter-reset: lst-ctn-kix_list_4-8 0;
}
ol.lst-kix_list_3-3.start {
  counter-reset: lst-ctn-kix_list_3-3 0;
}
.lst-kix_list_3-0 > li {
  counter-increment: lst-ctn-kix_list_3-0;
}
.lst-kix_list_3-3 > li {
  counter-increment: lst-ctn-kix_list_3-3;
}
ol.lst-kix_list_4-0.start {
  counter-reset: lst-ctn-kix_list_4-0 7;
}
.lst-kix_list_3-6 > li {
  counter-increment: lst-ctn-kix_list_3-6;
}
ol.lst-kix_list_3-2.start {
  counter-reset: lst-ctn-kix_list_3-2 0;
}
ol.lst-kix_list_4-7.start {
  counter-reset: lst-ctn-kix_list_4-7 0;
}
.lst-kix_list_2-6 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_2-7 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_3-7 > li {
  counter-increment: lst-ctn-kix_list_3-7;
}
.lst-kix_list_2-4 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_2-5 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_2-8 > li:before {
  content: "\0025aa   ";
}
ol.lst-kix_list_4-6.start {
  counter-reset: lst-ctn-kix_list_4-6 0;
}
ol.lst-kix_list_3-0.start {
  counter-reset: lst-ctn-kix_list_3-0 0;
}
ol.lst-kix_list_4-3.start {
  counter-reset: lst-ctn-kix_list_4-3 0;
}
.lst-kix_list_4-7 > li {
  counter-increment: lst-ctn-kix_list_4-7;
}
ol.lst-kix_list_3-8.start {
  counter-reset: lst-ctn-kix_list_3-8 0;
}
.lst-kix_list_4-0 > li:before {
  content: "" counter(lst-ctn-kix_list_4-0, decimal) ". ";
}
.lst-kix_list_3-8 > li {
  counter-increment: lst-ctn-kix_list_3-8;
}
.lst-kix_list_4-1 > li:before {
  content: "" counter(lst-ctn-kix_list_4-1, lower-latin) ". ";
}
.lst-kix_list_4-6 > li {
  counter-increment: lst-ctn-kix_list_4-6;
}
.lst-kix_list_4-4 > li:before {
  content: "" counter(lst-ctn-kix_list_4-4, lower-latin) ". ";
}
.lst-kix_list_4-3 > li:before {
  content: "" counter(lst-ctn-kix_list_4-3, decimal) ". ";
}
.lst-kix_list_4-5 > li:before {
  content: "" counter(lst-ctn-kix_list_4-5, lower-roman) ". ";
}
.lst-kix_list_4-2 > li:before {
  content: "" counter(lst-ctn-kix_list_4-2, lower-roman) ". ";
}
.lst-kix_list_4-6 > li:before {
  content: "" counter(lst-ctn-kix_list_4-6, decimal) ". ";
}
.lst-kix_list_3-5 > li {
  counter-increment: lst-ctn-kix_list_3-5;
}
ol.lst-kix_list_4-0 {
  list-style-type: none;
}
.lst-kix_list_3-4 > li {
  counter-increment: lst-ctn-kix_list_3-4;
}
ol.lst-kix_list_4-1 {
  list-style-type: none;
}
ol.lst-kix_list_4-4.start {
  counter-reset: lst-ctn-kix_list_4-4 0;
}
ol.lst-kix_list_4-2 {
  list-style-type: none;
}
ol.lst-kix_list_4-3 {
  list-style-type: none;
}
ol.lst-kix_list_3-6.start {
  counter-reset: lst-ctn-kix_list_3-6 0;
}
ul.lst-kix_list_2-8 {
  list-style-type: none;
}
ol.lst-kix_list_4-8 {
  list-style-type: none;
}
ul.lst-kix_list_2-2 {
  list-style-type: none;
}
.lst-kix_list_1-0 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_list_2-3 {
  list-style-type: none;
}
ul.lst-kix_list_2-0 {
  list-style-type: none;
}
ul.lst-kix_list_2-1 {
  list-style-type: none;
}
ol.lst-kix_list_4-4 {
  list-style-type: none;
}
ul.lst-kix_list_2-6 {
  list-style-type: none;
}
ol.lst-kix_list_4-5 {
  list-style-type: none;
}
.lst-kix_list_1-1 > li:before {
  content: "o  ";
}
.lst-kix_list_1-2 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_2-7 {
  list-style-type: none;
}
ol.lst-kix_list_4-6 {
  list-style-type: none;
}
ul.lst-kix_list_2-4 {
  list-style-type: none;
}
ol.lst-kix_list_4-7 {
  list-style-type: none;
}
ul.lst-kix_list_2-5 {
  list-style-type: none;
}
.lst-kix_list_1-3 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_1-4 > li:before {
  content: "\0025aa   ";
}
ol.lst-kix_list_3-5.start {
  counter-reset: lst-ctn-kix_list_3-5 0;
}
.lst-kix_list_4-8 > li {
  counter-increment: lst-ctn-kix_list_4-8;
}
.lst-kix_list_1-7 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_1-5 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_1-6 > li:before {
  content: "\0025aa   ";
}
li.li-bullet-0:before {
  white-space: nowrap;
  display: inline-block;
  min-width: 18px;
}
.lst-kix_list_2-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_2-1 > li:before {
  content: "o  ";
}
.lst-kix_list_4-5 > li {
  counter-increment: lst-ctn-kix_list_4-5;
}
.lst-kix_list_1-8 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_2-2 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_2-3 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_4-2 > li {
  counter-increment: lst-ctn-kix_list_4-2;
}
.doc-content ol {
  margin: 0;
  padding: 0;
}
.doc-content table td,
.doc-content table th {
  padding: 0;
}
.ce1 {
  padding-top: 0px;

  padding-left: 0px;
  padding-bottom: 0px;
  line-height: 1;

  orphans: 2;
  widows: 2;
  text-align: justify;
  padding-right: 0px;
}
.ce6 {
  padding-top: 0px;

  padding-left: 0px;
  padding-bottom: 0px;
  line-height: 1;

  orphans: 2;
  widows: 2;
  text-align: left;
  padding-right: 0px;
}
.ce14 {
  color: #444444;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 36px;
  font-family: "Nunito Sans";
  font-style: normal;
}
.ce0 {
  color: #444444;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 14px;
  font-family: "Nunito Sans";
  font-style: normal;
}
.ce2 {
  color: #444444;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 20px;
  font-family: "Nunito Sans";
  font-style: normal;
}
.ce8 {
  color: #444444;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 28px;
  font-family: "Nunito Sans";
  font-style: normal;
}
.ce3 {
  color: #444444;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 14px;
  font-family: "Nunito Sans";
  font-style: normal;
}
.ce11 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 15px;
  font-family: "Nunito Sans";
  font-style: normal;
}
.ce9 {
  -webkit-text-decoration-skip: none;
  color: #0000ff;
  font-weight: 400;
  text-decoration-skip-ink: none;
  font-size: 14px;
  font-family: "Nunito Sans";
}
.ce7 {
  color: #444444;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 20px;
  font-family: "Nunito Sans";
  font-style: normal;
}
span.ce7,
span.ce8 {
  margin-top: 16px;
  margin-bottom: 4px;
  display: inline-block;
}
span.ce2 {
  margin-top: 8px;
  margin-bottom: 4px;
  display: inline-block;
}
.ce15 {
  padding-top: 0px;
  padding-bottom: 8px;
  line-height: 1.0791666666666666;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.ce4 {
  font-size: 14px;
  font-family: "Nunito Sans";
  color: #444444;
  font-weight: 400;
}
.ce16 {
  background-color: #ffffff;
  padding: 70.8px 70.8px 56.7px 70.8px;
}
.ce10 {
  padding: 0;
  margin: 0;
}
.ce12 {
  color: inherit;
}
.ce5 {
}
.ce13 {
  height: 11px;
}
.title {
  padding-top: 24px;
  color: #000000;
  font-weight: 700;
  font-size: 36px;
  padding-bottom: 6px;
  font-family: "Nunito Sans";
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 18px;
  color: #666666;
  font-size: 36px;
  padding-bottom: 4px;
  font-family: "Nunito Sans";
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.doc-content li {
  color: #000000;
  font-size: 16px;
  font-family: "Nunito Sans";
}
.doc-content p {
  margin: 0;
  font-size: 16px;
  font-family: "Nunito Sans";
}
.doc-content h1 {
  padding-top: 0px;
  color: #000000;
  font-weight: 700;
  font-size: 36px;
  padding-bottom: 8px;
  font-family: "Nunito Sans";
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.doc-content h2 {
  padding-top: 0px;
  color: #000000;
  font-weight: 700;
  font-size: 28px;
  padding-bottom: 8px;
  font-family: "Nunito Sans";
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.doc-content h3 {
  padding-top: 0px;
  color: #000000;
  font-weight: 700;
  font-size: 20px;
  padding-bottom: 8px;
  font-family: "Nunito Sans";
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.doc-content h4 {
  padding-top: 0px;
  color: #000000;
  font-weight: 700;
  font-size: 20px;
  padding-bottom: 8px;
  font-family: "Nunito Sans";
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.doc-content h5 {
  padding-top: 11px;
  color: #000000;
  font-weight: 700;
  font-size: 15px;
  padding-bottom: 2px;
  font-family: "Nunito Sans";
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.doc-content h6 {
  padding-top: 10px;
  color: #000000;
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 2px;
  font-family: "Nunito Sans";
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.ce2,
.ce7,
.ce8,
.ce14 {
  font-family: "exo 2";
}

span.ce3 {
  line-height: 2rem;
  font-size: 16px;
}
.ce16.doc-content {
  padding: 40.8px 20.8px 56.7px 20.8px;
}
.ce7.title-text {
  font-weight: 400;
}
