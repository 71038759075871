@import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLcf-5JS7Pt-XEl3ieo8uMtisaQnAMaQTej6fzumLTPTJGDt9_iYoJB-VVF7ZDKH-yQehg7PtNcYqIz7hZpgHt5I);
.lst-kix_list_4-1 > li {
  counter-increment: lst-ctn-kix_list_4-1;
}
ol.lst-kix_list_3-1 {
  list-style-type: none;
}
ol.lst-kix_list_3-2 {
  list-style-type: none;
}
.lst-kix_list_3-1 > li {
  counter-increment: lst-ctn-kix_list_3-1;
}
ol.lst-kix_list_3-3 {
  list-style-type: none;
}
ol.lst-kix_list_3-4.start {
  counter-reset: lst-ctn-kix_list_3-4 0;
}
.lst-kix_list_5-1 > li {
  counter-increment: lst-ctn-kix_list_5-1;
}
ol.lst-kix_list_3-4 {
  list-style-type: none;
}
ul.lst-kix_list_1-0 {
  list-style-type: none;
}
ol.lst-kix_list_3-0 {
  list-style-type: none;
}
.lst-kix_list_3-0 > li:before {
  content: "" counter(lst-ctn-kix_list_3-0, decimal) ". ";
}
ol.lst-kix_list_3-1.start {
  counter-reset: lst-ctn-kix_list_3-1 0;
}
.lst-kix_list_3-1 > li:before {
  content: "" counter(lst-ctn-kix_list_3-1, lower-latin) ". ";
}
.lst-kix_list_3-2 > li:before {
  content: "" counter(lst-ctn-kix_list_3-2, lower-roman) ". ";
}
.lst-kix_list_4-0 > li {
  counter-increment: lst-ctn-kix_list_4-0;
}
.lst-kix_list_5-0 > li {
  counter-increment: lst-ctn-kix_list_5-0;
}
ul.lst-kix_list_1-3 {
  list-style-type: none;
}
.lst-kix_list_3-5 > li:before {
  content: "" counter(lst-ctn-kix_list_3-5, lower-roman) ". ";
}
ul.lst-kix_list_1-4 {
  list-style-type: none;
}
ul.lst-kix_list_1-1 {
  list-style-type: none;
}
.lst-kix_list_3-4 > li:before {
  content: "" counter(lst-ctn-kix_list_3-4, lower-latin) ". ";
}
ul.lst-kix_list_1-2 {
  list-style-type: none;
}
ul.lst-kix_list_1-7 {
  list-style-type: none;
}
.lst-kix_list_3-3 > li:before {
  content: "" counter(lst-ctn-kix_list_3-3, decimal) ". ";
}
ol.lst-kix_list_3-5 {
  list-style-type: none;
}
ul.lst-kix_list_1-8 {
  list-style-type: none;
}
ol.lst-kix_list_3-6 {
  list-style-type: none;
}
ul.lst-kix_list_1-5 {
  list-style-type: none;
}
ol.lst-kix_list_3-7 {
  list-style-type: none;
}
ul.lst-kix_list_1-6 {
  list-style-type: none;
}
ol.lst-kix_list_3-8 {
  list-style-type: none;
}
.lst-kix_list_3-8 > li:before {
  content: "" counter(lst-ctn-kix_list_3-8, lower-roman) ". ";
}
ol.lst-kix_list_5-3.start {
  counter-reset: lst-ctn-kix_list_5-3 0;
}
.lst-kix_list_3-6 > li:before {
  content: "" counter(lst-ctn-kix_list_3-6, decimal) ". ";
}
.lst-kix_list_4-3 > li {
  counter-increment: lst-ctn-kix_list_4-3;
}
.lst-kix_list_3-7 > li:before {
  content: "" counter(lst-ctn-kix_list_3-7, lower-latin) ". ";
}
ol.lst-kix_list_4-5.start {
  counter-reset: lst-ctn-kix_list_4-5 0;
}
ol.lst-kix_list_5-0.start {
  counter-reset: lst-ctn-kix_list_5-0 0;
}
ol.lst-kix_list_3-7.start {
  counter-reset: lst-ctn-kix_list_3-7 0;
}
.lst-kix_list_5-2 > li {
  counter-increment: lst-ctn-kix_list_5-2;
}
ol.lst-kix_list_4-2.start {
  counter-reset: lst-ctn-kix_list_4-2 0;
}
.lst-kix_list_3-2 > li {
  counter-increment: lst-ctn-kix_list_3-2;
}
.lst-kix_list_5-0 > li:before {
  content: "" counter(lst-ctn-kix_list_5-0, decimal) ". ";
}
.lst-kix_list_5-4 > li {
  counter-increment: lst-ctn-kix_list_5-4;
}
.lst-kix_list_4-4 > li {
  counter-increment: lst-ctn-kix_list_4-4;
}
.lst-kix_list_4-8 > li:before {
  content: "" counter(lst-ctn-kix_list_4-8, lower-roman) ". ";
}
.lst-kix_list_5-3 > li:before {
  content: "" counter(lst-ctn-kix_list_5-3, decimal) ". ";
}
.lst-kix_list_4-7 > li:before {
  content: "" counter(lst-ctn-kix_list_4-7, lower-latin) ". ";
}
.lst-kix_list_5-2 > li:before {
  content: "" counter(lst-ctn-kix_list_5-2, lower-roman) ". ";
}
.lst-kix_list_5-1 > li:before {
  content: "" counter(lst-ctn-kix_list_5-1, lower-latin) ". ";
}
.lst-kix_list_5-7 > li:before {
  content: "" counter(lst-ctn-kix_list_5-7, lower-latin) ". ";
}
ol.lst-kix_list_5-6.start {
  counter-reset: lst-ctn-kix_list_5-6 0;
}
.lst-kix_list_5-6 > li:before {
  content: "" counter(lst-ctn-kix_list_5-6, decimal) ". ";
}
.lst-kix_list_5-8 > li:before {
  content: "" counter(lst-ctn-kix_list_5-8, lower-roman) ". ";
}
ol.lst-kix_list_4-1.start {
  counter-reset: lst-ctn-kix_list_4-1 0;
}
ol.lst-kix_list_4-8.start {
  counter-reset: lst-ctn-kix_list_4-8 0;
}
ol.lst-kix_list_3-3.start {
  counter-reset: lst-ctn-kix_list_3-3 0;
}
.lst-kix_list_5-4 > li:before {
  content: "" counter(lst-ctn-kix_list_5-4, lower-latin) ". ";
}
.lst-kix_list_5-5 > li:before {
  content: "" counter(lst-ctn-kix_list_5-5, lower-roman) ". ";
}
.lst-kix_list_3-0 > li {
  counter-increment: lst-ctn-kix_list_3-0;
}
.lst-kix_list_3-3 > li {
  counter-increment: lst-ctn-kix_list_3-3;
}
ol.lst-kix_list_4-0.start {
  counter-reset: lst-ctn-kix_list_4-0 0;
}
.lst-kix_list_3-6 > li {
  counter-increment: lst-ctn-kix_list_3-6;
}
ol.lst-kix_list_3-2.start {
  counter-reset: lst-ctn-kix_list_3-2 0;
}
ol.lst-kix_list_5-5.start {
  counter-reset: lst-ctn-kix_list_5-5 0;
}
ol.lst-kix_list_4-7.start {
  counter-reset: lst-ctn-kix_list_4-7 0;
}
ol.lst-kix_list_5-0 {
  list-style-type: none;
}
.lst-kix_list_2-6 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_2-7 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_3-7 > li {
  counter-increment: lst-ctn-kix_list_3-7;
}
ol.lst-kix_list_5-1 {
  list-style-type: none;
}
ol.lst-kix_list_5-2 {
  list-style-type: none;
}
.lst-kix_list_2-4 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_2-5 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_2-8 > li:before {
  content: "\0025aa   ";
}
ol.lst-kix_list_5-4.start {
  counter-reset: lst-ctn-kix_list_5-4 0;
}
ol.lst-kix_list_4-6.start {
  counter-reset: lst-ctn-kix_list_4-6 0;
}
ol.lst-kix_list_5-1.start {
  counter-reset: lst-ctn-kix_list_5-1 0;
}
ol.lst-kix_list_3-0.start {
  counter-reset: lst-ctn-kix_list_3-0 0;
}
ol.lst-kix_list_5-7 {
  list-style-type: none;
}
ol.lst-kix_list_5-8 {
  list-style-type: none;
}
.lst-kix_list_5-7 > li {
  counter-increment: lst-ctn-kix_list_5-7;
}
ol.lst-kix_list_4-3.start {
  counter-reset: lst-ctn-kix_list_4-3 0;
}
ol.lst-kix_list_5-3 {
  list-style-type: none;
}
.lst-kix_list_4-7 > li {
  counter-increment: lst-ctn-kix_list_4-7;
}
ol.lst-kix_list_5-4 {
  list-style-type: none;
}
ol.lst-kix_list_3-8.start {
  counter-reset: lst-ctn-kix_list_3-8 0;
}
ol.lst-kix_list_5-5 {
  list-style-type: none;
}
ol.lst-kix_list_5-6 {
  list-style-type: none;
}
.lst-kix_list_5-8 > li {
  counter-increment: lst-ctn-kix_list_5-8;
}
.lst-kix_list_4-0 > li:before {
  content: "" counter(lst-ctn-kix_list_4-0, decimal) ". ";
}
.lst-kix_list_3-8 > li {
  counter-increment: lst-ctn-kix_list_3-8;
}
.lst-kix_list_4-1 > li:before {
  content: "" counter(lst-ctn-kix_list_4-1, lower-latin) ". ";
}
.lst-kix_list_4-6 > li {
  counter-increment: lst-ctn-kix_list_4-6;
}
.lst-kix_list_4-4 > li:before {
  content: "" counter(lst-ctn-kix_list_4-4, lower-latin) ". ";
}
.lst-kix_list_4-3 > li:before {
  content: "" counter(lst-ctn-kix_list_4-3, decimal) ". ";
}
.lst-kix_list_4-5 > li:before {
  content: "" counter(lst-ctn-kix_list_4-5, lower-roman) ". ";
}
.lst-kix_list_4-2 > li:before {
  content: "" counter(lst-ctn-kix_list_4-2, lower-roman) ". ";
}
.lst-kix_list_4-6 > li:before {
  content: "" counter(lst-ctn-kix_list_4-6, decimal) ". ";
}
ol.lst-kix_list_5-7.start {
  counter-reset: lst-ctn-kix_list_5-7 0;
}
.lst-kix_list_5-5 > li {
  counter-increment: lst-ctn-kix_list_5-5;
}
.lst-kix_list_3-5 > li {
  counter-increment: lst-ctn-kix_list_3-5;
}
ol.lst-kix_list_4-0 {
  list-style-type: none;
}
.lst-kix_list_3-4 > li {
  counter-increment: lst-ctn-kix_list_3-4;
}
ol.lst-kix_list_4-1 {
  list-style-type: none;
}
ol.lst-kix_list_4-4.start {
  counter-reset: lst-ctn-kix_list_4-4 0;
}
ol.lst-kix_list_4-2 {
  list-style-type: none;
}
ol.lst-kix_list_4-3 {
  list-style-type: none;
}
ol.lst-kix_list_3-6.start {
  counter-reset: lst-ctn-kix_list_3-6 0;
}
.lst-kix_list_5-3 > li {
  counter-increment: lst-ctn-kix_list_5-3;
}
ul.lst-kix_list_2-8 {
  list-style-type: none;
}
ol.lst-kix_list_4-8 {
  list-style-type: none;
}
ul.lst-kix_list_2-2 {
  list-style-type: none;
}
.lst-kix_list_1-0 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_list_2-3 {
  list-style-type: none;
}
ul.lst-kix_list_2-0 {
  list-style-type: none;
}
ul.lst-kix_list_2-1 {
  list-style-type: none;
}
ol.lst-kix_list_4-4 {
  list-style-type: none;
}
ul.lst-kix_list_2-6 {
  list-style-type: none;
}
ol.lst-kix_list_4-5 {
  list-style-type: none;
}
.lst-kix_list_1-1 > li:before {
  content: "o  ";
}
.lst-kix_list_1-2 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_2-7 {
  list-style-type: none;
}
ol.lst-kix_list_4-6 {
  list-style-type: none;
}
ul.lst-kix_list_2-4 {
  list-style-type: none;
}
ol.lst-kix_list_4-7 {
  list-style-type: none;
}
ul.lst-kix_list_2-5 {
  list-style-type: none;
}
.lst-kix_list_1-3 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_1-4 > li:before {
  content: "\0025aa   ";
}
ol.lst-kix_list_3-5.start {
  counter-reset: lst-ctn-kix_list_3-5 0;
}
.lst-kix_list_4-8 > li {
  counter-increment: lst-ctn-kix_list_4-8;
}
.lst-kix_list_1-7 > li:before {
  content: "\0025aa   ";
}
ol.lst-kix_list_5-8.start {
  counter-reset: lst-ctn-kix_list_5-8 0;
}
.lst-kix_list_1-5 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_1-6 > li:before {
  content: "\0025aa   ";
}
li.li-bullet-0:before {
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.lst-kix_list_5-6 > li {
  counter-increment: lst-ctn-kix_list_5-6;
}
.lst-kix_list_2-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_2-1 > li:before {
  content: "o  ";
}
.lst-kix_list_4-5 > li {
  counter-increment: lst-ctn-kix_list_4-5;
}
.lst-kix_list_1-8 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_2-2 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_2-3 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_4-2 > li {
  counter-increment: lst-ctn-kix_list_4-2;
}
ol.lst-kix_list_5-2.start {
  counter-reset: lst-ctn-kix_list_5-2 0;
}
.doc-content ol {
  margin: 0;
  padding: 0;
}
.doc-content table td,
.doc-content table th {
  padding: 0;
}
.cd2 {
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
  padding-right: 0pt;
}
.cd1 {
  padding-top: 0pt;

  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1;

  orphans: 2;
  widows: 2;
  padding-right: 0pt;
}
.cd3 {
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
  padding-right: 0pt;
}
.cd10 {
  color: #444444;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 28px;
  font-family: "Nunito Sans";
  font-style: normal;
}
.cd16 {
  -webkit-text-decoration-skip: none;
  color: #3367d6;
  font-weight: 400;
  text-decoration-skip-ink: none;
  font-size: 16px;
  font-family: "Nunito Sans";
}
.cd0 {
  color: #444444;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 16px;
  font-family: "Nunito Sans";
  font-style: normal;
}
.cd20 {
  color: #444444;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 32px;
  font-family: "Nunito Sans";
  font-style: normal;
}
.cd19 {
  color: #444444;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 20px;
  font-family: "Nunito Sans";
  font-style: normal;
}
.cd7 {
  color: #444444;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 20px;
  font-family: "Nunito Sans";
  font-style: normal;
}
.cd6 {
  color: #444444;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 20px;
  font-family: "Exo 2";
  font-style: normal;
}
.cd4 {
  -webkit-text-decoration-skip: none;
  color: #0000ff;
  font-weight: 400;
  text-decoration-skip-ink: none;
  font-size: 16px;
  font-family: "Nunito Sans";
}
.cd11 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 16px;
  font-family: "Nunito Sans";
  font-style: normal;
}
.cd22 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 1.0791666666666666;
  orphans: 2;
  widows: 2;
}
.cd14 {
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.cd21 {
  line-height: 1;
  orphans: 2;
  widows: 2;
}
.cd17 {
  background-color: #ffffff;
  font-size: 16px;
  font-family: "Nunito Sans";
  color: #000000;
  font-weight: 400;
}
.cd18 {
  background-color: #ffffff;
  font-size: 16px;
  font-family: "Nunito Sans";
  font-weight: 400;
}
.cd12 {
  font-size: 16px;
  font-family: "Nunito Sans";
  color: #444444;
  font-weight: 400;
}
.cd15 {
  background-color: #ffffff;
  padding: 70.8pt 70.8pt 56.7pt 70.8pt;
}
.cd5 {
  padding: 0;
  margin: 0;
}
.cd8 {
  color: inherit;
}
.cd13 {
  height: 11pt;
}

.doc-content .title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36px;
  padding-bottom: 6pt;
  font-family: "Nunito Sans";
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.doc-content .subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 32px;
  padding-bottom: 4pt;
  font-family: "Nunito Sans";
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.doc-content li {
  color: #000000;
  font-size: 16px;
  font-family: "Nunito Sans";
}
.doc-content p {
  margin: 0;
  font-size: 16px;
  font-family: "Nunito Sans";
}
.doc-content h1 {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 32px;
  padding-bottom: 6pt;
  font-family: "Nunito Sans";
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.doc-content h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 28px;
  padding-bottom: 4pt;
  font-family: "Nunito Sans";
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.doc-content h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 20px;
  padding-bottom: 4pt;
  font-family: "Nunito Sans";
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.doc-content h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 20px;
  padding-bottom: 2pt;
  font-family: "Nunito Sans";
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.doc-content h5 {
  padding-top: 11pt;
  color: #000000;
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 2pt;
  font-family: "Nunito Sans";
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.doc-content h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 2pt;
  font-family: "Nunito Sans";
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.cd15.doc-content {
  padding: 40.8px 20.8px 56.7px 20.8px;
}
span.cd20,
span.cd19,
span.cd7,
span.cd10,
span.cd6 {
  font-family: "exo 2";
  margin-top: 16px;
  margin-bottom: 8px;
  display: inline-block;
}
span.cd10 {
  margin-top: 24px;
  margin-bottom: 4px;
}
.cd19.title-text {
  font-weight: 400;
}
